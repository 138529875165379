import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import OurPortfolio from '../../components/Portfolio'
import { graphql, useStaticQuery } from 'gatsby'
import { toLower, capitalize } from 'lodash'
import './Search.scss'

const optimizedProperties = (properties: any, optimizedImgs: any) => {
  const images = optimizedImgs?.allSitePage?.edges
  const result = properties?.map((property: any) => {
    const propertyPath = `/our-portfolio/${property?.slug}/`
    const imageNode = images?.find((img: any) => img?.node?.path === propertyPath)?.node?.optimizedPreviewImg
    property.optimizedPreviewImg = imageNode
    return property
  })

  return result
}

function Search({ location, pageContext: { properties } }: any) {
  const [propertiesList, setPropertiesList] = useState<any>([])
  const loc = new URLSearchParams(location.search).get('location')
  const propertyType = new URLSearchParams(location.search).get('property-type')

  const optimizedImgs = useStaticQuery(graphql`
    query {
      allSitePage(filter: { context: { type: { eq: "SingleProperty" }, category: { eq: "properties" } } }) {
        edges {
          node {
            optimizedPreviewImg {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            path
          }
        }
      }
    }
  `)

  const alpProperties = optimizedProperties(properties, optimizedImgs)

  const setContent = () => {
    let tempData = alpProperties

    if (loc) {
      tempData = tempData.filter(({ propertyDetails }: any) => {
        return toLower(propertyDetails?.location) === toLower(loc)
      })
    }

    if (propertyType) {
      tempData = tempData.filter(({ propertyDetails }: any) => {
        return toLower(propertyDetails?.propertyType) === toLower(propertyType)
      })
    }

    setPropertiesList(tempData)
  }

  useEffect(() => {
    setContent()
  }, [])

  return (
    <Layout
      {...{
        props: {
          portfolio: false,
          layoutStyle: 'portfolio-layout'
        }
      }}
    >
      <div className="portfolio-search">
        <header>
          <h2>Search for "{loc || propertyType}"</h2>
        </header>
        <OurPortfolio
          {...{
            propertiesList,
            alpProperties
          }}
        />
      </div>
    </Layout>
  )
}

export default Search
