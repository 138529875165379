import React from 'react'
import loadable from '@loadable/component'
const PortfolioSlider = loadable(() => import('./PortfolioSlider/PortfolioSlider'))
import './Portfolio.scss'

const OurPortfolio = ({ propertiesList, selectedProject, alpProperties, showSearch, filters }: any) => {
  return (
    <>
      <div className={`portfolio-page ${showSearch ? 'portfolio-page--toggle-search' : ''}`}>
        <div className="portfolio-container">
          <PortfolioSlider
            {...{
              propertiesList,
              alpProperties,
              showSearch,
              filters,
              selectedProject
            }}
          />
        </div>
      </div>
    </>
  )
}

export default OurPortfolio
